import React from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

export const useQueryString = () => {
  const [searchValues, setSearchValues] = React.useState({})
  const location = useLocation()

  React.useEffect(() => {
    const urlParams = queryString.parse(location.search)
    setSearchValues(urlParams)
  }, [location.search])

  return searchValues
}
