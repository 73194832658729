import React from 'react'
import styled from 'styled-components'

import { CONTENTFUL_PREFIX } from '../common/utils'
import { useQueryString } from '../hooks/useQueryString'

const SocialCardsPage = () => {
  const { i } = useQueryString()
  const image = `${CONTENTFUL_PREFIX}${i}`

  return <BackgroundImage image={image} />
}

const BackgroundImage = styled.div`
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &:before {
    background: transparent url('${({ image }) => image}') no-repeat center;
    background-size: cover;
    z-index: 1;
  }
`

export default SocialCardsPage
